import algoliasearch from "algoliasearch";

const appID = "78YVFYU3CB";
const searchOnlyAPIKey = "cd516e11d92c9b4de5905deeeda21c4e";

export const algolia = {
    namespaced: true,
    state: {
        client: algoliasearch(appID, searchOnlyAPIKey),
        indexes: {
            //contacts: "contacts",
            centres: "centres",
            cities: "cities",
            //customers: "customers",
            //equipment: "equipment",
            installation: "installation",
        },
        facets: {
            contacts: ["name", "email", "phone", "city"],
            centres: ["city.name", "dgt", "customer.id"],
            cities: ["ca", "province", "customers"],
            equipment: ["system"],
            installation: ["ceco", "centres.name", "sociedad", "customer"],
        },
        applyFacets: {
            centres: [
                { "customer.id": "recbKtsrFj6H0Qxyw" }
            ],
            installation: [
                { "customer": "recbKtsrFj6H0Qxyw" }
            ],
            cities: [
                { "customers": "recbKtsrFj6H0Qxyw" }
            ],
        },
        facetValues: {
            contacts: {
                name: [],
                email: [],
                phone: [],
                city: []
            },
            centres: {
                city: [],
                dgt: []
            },
            cities: {
                ca: [],
                province: []
            },
            equipment: {
                system: []
            },
            installation: {
                ceco: [],
                centres: [],
                sociedad: []
            }
        },
        searches: {},
        results: {},
        key_display_map: {
            "city": "City",
            "dgt": "DGT",
            "customer.id": "Customer",
            "ca": "Comunidad Autónoma",
            "province": "Province",
            "sociedad": "Sociedad a facturar",
        },
        result_highlights: {}
    },
    getters: {
        getIndex: (state) => (index) => {
            return state.client.initIndex(index);
        },
        getFacets: (state) => (index) => {
            return state.facets[index];
        },
        getFacetValues: (state) => (index, facet) => {
            return state.facetValues[index][facet];
        },
        getResults: (state) => {
            return state.results;
        },
        getResultHighlights: (state) => {
            return state.result_highlights;
        }
    },
    mutations: {
        setFacetValues: (state, { index, facet, values }) => {
            state.facetValues[index][facet] = values;
        },
        setResults: (state, { index, results }) => {
            state.results[index] = results;
        },
        setResultHighlights: (state, highlights) => {
            state.result_highlights = highlights;
        }
    },
    actions: {
        loadFacetValues: function(context, { index, facet }) {
            return new Promise((resolve, reject) => {
                context.getters.getIndex(index).searchForFacetValues({
                    facetName: facet,
                    maxFacetHits: 100
                }).then(r => {
                    context.commit("setFacetValues", { index, facet, values: r.facetHits });
                    resolve(r.facetHits);
                }).catch(e => {
                    console.error(e);
                    reject(e);
                });
            });
        },
        clearResults: function(context) {
            context.state.results = {};
            context.state.result_highlights = {};
        },
        search: function(context, { query }) {
            return new Promise((resolve, reject) => {
                context.dispatch("clearResults");
                const client = context.state.client;
                const searches = Object.keys(context.state.indexes).map(indexName => {
                    const indexId = context.state.indexes[indexName];
                    const facets = context.state.applyFacets[indexName] || [];
                    const facetFilters = facets.map(facet => {
                        return `${Object.keys(facet)[0]}:${facet[Object.keys(facet)[0]]}`;
                    });
                    const searchableAttributes = context.state.facets[indexName] || [];

                    console.log({ indexId, query, facetFilters, searchableAttributes })

                    return {
                        indexName: indexId,
                        query: query,
                        params: {
                            facetFilters: facetFilters,
                            restrictSearchableAttributes: searchableAttributes,
                            //hitsPerPage: 10 // Adjust based on your needs
                        }
                    };
                });

                client.multipleQueries(searches).then(({ results }) => {
                    let highlights = {}
                    results.forEach(result => {
                        //console.log(result);
                        if(result.hits && result.hits.length > 0) {
                            // check if _highlightResult exists and if it has a key that is in the searchableAttributes for this index and if there is a key that has matchLevel: "full"
                            let searchableAttributes = context.state.facets[result.index];
                            // get an array of _highlightResult from all hits
                            let highlightResults = result.hits.map(hit => hit._highlightResult);
                            
                            // iterate through the highlightResults array 
                            highlightResults.forEach(highlightResult => {
                                // iterate though each result
                                Object.keys(highlightResult).forEach(key => {
                                    // if the key is the searchableAttribute
                                    let hr = highlightResult[key];
                                    let hr_key = key;
                                    let first_hr = hr;
                                    let sub_key = null;

                                    if(Array.isArray(hr)) {
                                        first_hr = hr[0];
                                        sub_key = Object.keys(first_hr)[0];
                                        hr_key = key + "." + sub_key;
                                        // console.log("hr is array", hr_key, first_hr);
                                        first_hr = first_hr[sub_key];
                                    }

                                    if(searchableAttributes.includes(hr_key)) {
                                        // if the matchLevel is "full"
                                        if(first_hr.matchLevel === "full") {
                                            // add the key to the highlights object
                                            let h_desc = " in " + (context.state.key_display_map[key] ? context.state.key_display_map[key] : key);
                                           //  if(sub_key) h_desc = h_desc; // + " " + sub_key; don't need to show sub_key
                                            if(!highlights[hr_key]) {
                                                h_desc = first_hr.value + h_desc;
                                                highlights[hr_key] = h_desc;
                                            }

                                            //console.log({ hr, hr_key, first_hr, h_desc})
                                        }    
                                    }
                                });
                            });
                            console.log("highlights", highlights);
                            context.commit('setResultHighlights', highlights);
                        }
                        context.commit('setResults', { index: result.index, results: result.hits });
                    });
                    resolve(results);
                }).catch(error => {
                    console.error('Error during search:', error);
                    reject(error);
                });
            });
        },
    }
}