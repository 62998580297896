// eslint-disable-next-line no-unused-vars
import _, { keys, reverse } from "lodash";
import Vue from "vue";
import moment from "moment";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { setDoc, collection, getFirestore, doc, query, getDocs, Timestamp, orderBy, where, limit, deleteDoc, getDoc, DocumentReference, CollectionReference } from "firebase/firestore";
import { each } from "jquery";

const firebaseConfig = {
    apiKey: "AIzaSyBMAbftqUcxz7xUE9vDNi0x6pajBh47I38",
    authDomain: "my-kmzero.firebaseapp.com",
    projectId: "my-kmzero",
    storageBucket: "my-kmzero.appspot.com",
    messagingSenderId: "198048815027",
    appId: "1:198048815027:web:2e16200578b09a25ea9dc6",
    measurementId: "G-NYFG9R4ENK"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);

const isObject = function(a) {
    return (!!a) && (a.constructor === Object);
};

const isArray = function(a) {
    return (!!a) && (a.constructor === Array);
};


export const fbase = {
    namespaced: true,
    state: {
        db: {},
        firestore: firestore,
        auth: auth,
        account: null,
        customer: null,
        tables: ["accounts", "customers", "equipment", "installation", "centres", "cities", "maintenance", "customer_bottles", "bottles", "kmzero_content", "comparative"],
        accounts: {},
        customers: {},
        equipment: {},
        installation: {},
        centres: {},
        cities: {},
        maintenance: {},
        customer_bottles: {},
        bottles: {},
        kmzero_content: {},
        comparitive: {},
        userReady: false
    },
    getters: {
        getFS: state => { return state.firestore; },
        getDB: state => { return state.db; },
        getRecord: state => ({ table, ref, id }) => { 
            if(!ref && (!id && !table)) return null;
            if(!ref && table && !id) return null;
            if(!ref && id && !table) return null;
            if(ref && !id) id = ref.id;
            if(id && !ref) ref = doc(state.db[table], id);
            if(!table && ref) {
                //console.log(ref);
                table = ref.parent.id;
            }

            return state[table][id]; 
        },
        getUserReady: state => {
            return state.userReady;
        },
        getTable: state => (table) => {
            return _.values(state[table]);
        },
        isMapfre: state => {
            return state.account && state.account.username === "mapfre";
        }
    },
    mutations: {
        SETUP_FIREBASE: (state) => {
            _.each(state.tables, table => {
                state.db[table] = collection(firestore, table);
            })
        },
        SET_RECORD: (state, { table, id, data }) => {
            _.each(data, (val, key) => {
                if(val && val.toDate) {
                    // console.log("SET_RECORD to DATE", key, val);
                    data[key] = moment(val.toDate());
                }
            })
            if(data) data.order = _.size(state[table]);
            // console.log("SET_RECORD", table, id, data);
            Vue.set(state[table], id, data);
        },
        SET_KEY: (state, payload) => {
            var { key, val } = payload;
            if(!key) {
                _.each(payload, (v, k) => {
                    Vue.set(state, k, v);
                })
            } else {
                Vue.set(state, key, val);
            }
            
        },
        RESET_TABLE: (state) => {
            _.each(state.tables, table => {
                Vue.set(state, table, {});
            })
            Vue.set(state, "account", null);
            Vue.set(state, "customer", null);
        },
    },
    actions: {
        setupFirebase: function(context) {
            return new Promise((resolve, reject) => {
                console.log("setupFirebase");
                context.commit("SETUP_FIREBASE");
                //context.dispatch("auth/setupAuth", auth, { root: true });
                resolve(true);
            })
        },
        loadTable: function(context, { table, conditions }) {
            return new Promise((resolve, reject) => {
                var coll = context.state.db[table];
                var ref = query(coll);

                console.log(table, conditions);
 
                // eslint-disable-next-line no-unused-vars
                if(conditions) each(conditions, (index, condition) => {
                    var op = condition.op || "==";
                    ref = query(ref, where(condition.key, op, condition.val));
                })

                //TODO: testing only get 10 docs
                // ref = query(ref, limit(10));

                getDocs(ref).then(snap => {
                    if(snap.empty) {
                        console.log("no docs found in", table);
                        resolve();
                    } else {
                        snap.forEach(d => {
                            context.commit("SET_RECORD", { table, id: d.id, data: d.data() });
                        })
                        console.log(snap.docs.length, "docs found in", table);
                        resolve();
                    }
                })
                .catch(err => {
                    console.log("error loading table", table);
                    console.error(err);
                    reject(err);
                })
            })
        },
        loadRecord: function(context, { table, id, ref, follow, reload }) {
            return new Promise(async (resolve, reject) => {
                if(!ref && (!id && !table)) return reject("Need at least one of ref or id to proceed");
                if(!ref && table && !id) return reject("Need id with table");
                if(!ref && id && !table) return reject("Need table with id");
                if(ref && !id) id = ref.id;
                if(id && !ref) ref = doc(context.state.db[table], id);
                if(!table && ref) {
                    //console.log("ref", ref);
                    table = ref.parent ? ref.parent.id : null;
                }

                //console.log({table, id, ref});

                if(!reload && context.state[table][id]) return resolve(context.state[table][id]);

                //console.log(table, "...getting from the server");

                getDoc(ref)
                .then(dsnap => {
                    if(dsnap.exists) {
                        var data = dsnap.data();
                        context.commit("SET_RECORD", { table, id, data });
                        //console.log({table, id, data});
                        resolve(data);
                    } else {
                        reject("No document exists for", table, id);
                    }
                })
                .catch(err => {
                    console.log("error loading record", table, id);
                    console.error(err);
                    reject(err);
                })

            })
        },
        loadAccount: function(context, uid) {
            return new Promise(async (resolve, reject) => {
                if(!uid) return reject("No uid provided");

                var accountshot = await getDocs(query(context.state.db.accounts, where("username", "==", uid), limit(1)));

                if(accountshot.empty) {
                    return reject("No account found for " + uid);
                } else {
                    var account = accountshot.docs[0].data();
                    context.commit("SET_KEY", { key: "account", val: account });
                    resolve(account);
                }

            })
        },
        reset: function(context) {
            context.commit("RESET_TABLE");
        },
        getUserData: function(context, uid) {
            return new Promise(async (resolve, reject) => {
                if(!uid) return reject("getUserData: No uid provided");

                context.dispatch("layout/setWorking", true, { root: true });

                var account, customer;
                var tasks = [];
                // TODO: remove before production
                const TEST = false;
                const TEST_N = 5;

                try {
                    account = await context.dispatch("loadAccount", uid);
                } catch(err) {
                    return reject(err);
                }

                if(account && account.customer && account.customer[0]) {
                    console.log("getUserData: account loaded");

                    try {
                       //console.log("1. calling loadRecord", account.customer[0]);
                        let ac = account.customer[0];
                        if(ac) customer = await context.dispatch("loadRecord", { ref: ac });
                    } catch(err) {
                        return reject(err);
                    }


                    if(customer) {
                        context.commit("SET_KEY", { key: "customer", val: customer });
                        console.log("getUserData: customer loaded");
                        
                        if(customer.centres) {
                            if(TEST) customer.centres = customer.centres.slice(0, TEST_N);
                            console.log("getUserData: loading centres", customer.centres.length);
                            _.each(customer.centres, centre => {
                                //console.log("2. calling loadRecord", centre);
                                if(centre) tasks[tasks.length] = context.dispatch("loadRecord", { ref: centre });
                            })
                        }
                        
                        if(customer.city) {
                            if(TEST) customer.city = customer.city.slice(0, TEST_N);
                            console.log("getUserData: loading city", customer.city.length);
                            _.each(customer.city, city => {
                                //console.log("3. calling loadRecord", city);
                                if(city) tasks[tasks.length] = context.dispatch("loadRecord", { ref: city });
                            })
                        }

                        if(customer.customer_bottles) {
                            console.log("getUserData: loading customer_bottles", customer.customer_bottles.length);
                            _.each(customer.customer_bottles, customer_bottle => {
                                //console.log("4. calling loadRecord", customer_bottle);
                                tasks[tasks.length] = context.dispatch("loadRecord", { ref: customer_bottle }).then(cb => {
                                    _.each(cb.bottle, bottle => {
                                        //console.log("5. calling loadRecord", bottle);
                                        if(bottle) tasks[tasks.length] = context.dispatch("loadRecord", { ref: bottle });
                                    })
                                })
                            })
                        }

                        if(customer.installed_equipment) {
                            if(TEST) customer.installed_equipment = customer.installed_equipment.slice(0, TEST_N);
                            console.log("getUserData: loading installed_equipment", customer.installed_equipment.length);
                            _.each(customer.installed_equipment, equipment => {
                                //console.log("6. calling loadRecord", equipment);
                                tasks[tasks.length] = context.dispatch("loadRecord", { ref: equipment }).then(eq => {
                                    if(eq && eq.equipment) {
                                        _.each(eq.equipment, equip => {
                                            //console.log("7. calling loadRecord", equip);
                                            if(equip) tasks[tasks.length] = context.dispatch("loadRecord", { ref: equip });
                                        })
                                    }
                                    if(eq && eq.service) {
                                        _.each(eq.service, service => {
                                            //console.log("8. calling loadRecord", service);
                                            if(service) tasks[tasks.length] = context.dispatch("loadRecord", { ref: service });
                                        })
                                    }
                                })
                            })
                        }

                        if(customer.kmzero_content) {
                            console.log("getUserData: loading kmzero_content", customer.kmzero_content.length);
                            _.each(customer.kmzero_content, kmzero_content => {
                                //console.log("9. calling loadRecord", kmzero_content);
                                tasks[tasks.length] = context.dispatch("loadRecord", { ref: kmzero_content });
                            })
                        }

                        context.dispatch("loadTable", {table: "kmzero_content", conditions: [ { key: 'show_to_all_customers', val: true }]});
                        
                    }

                }

                Promise.all(tasks).then(() => {
                    console.log(tasks.length, "records loaded");
                    context.commit("SET_KEY", { userReady: true });
                    context.dispatch("layout/setWorking", false, { root: true });
                    resolve(customer);
                    //context.dispatch("fixReferences");
                })


            })
        }
    }
}